@use 'sass:color';

$border-radius: 2px;
$primary: #558955;
$secondary: #939393;
$info: #6a9ba3;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$light-error: #f5ced2;
$dark-error: #98171e;
$body-text: #7f7f7f;
$body-bg: #ffffff;
$headings-text: #242424;
$subtle-text: #7a7b7d;
$disabled-text: #8f8f8f;
$component-text: #7f7f7f;
$component-bg: #ffffff;
$base-text: #7f7f7f;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #6a9ba3;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$link-text: #558955;
$link-hover-text: #7a7b7d;
$series-a: #ff6358;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #4b5ffa;
$series-e: #ac58ff;
$series-f: #ff5892;
$k-button-md: #6a9ba3;
$light: #ffffff;
$selected-blue: #64b9c7;
$baby-blue: #a1eeef;

$kendo-button-border-radius: 0.25rem;
$kendo-button-font-family: 'Arial', sans-serif;
$btn-padding-y: 0.75rem;
$btn-padding-x: 2rem;
$grid-header-bg: #e9f0e9;
$grid-header-text: #{$primary};
$grid-footer-bg: #{$grid-header-bg};
$grid-border: #{$primary};
$grid-alt-bg: unset;
$grid-hovered-bg: unset;

// Kendo theme customization
@import '@progress/kendo-theme-core/scss/functions/index.import.scss';

$kendo-colors: ();
$kendo-colors: k-map-merge(
    $kendo-colors,
    k-generate-color-variations('primary', #558955, 'bootstrap'),
    k-generate-color-variations('tertiary', #28B4D8, 'bootstrap')
);
//$kendo-color-secondary: #939393;

// Bootstrap overrides
$body-color: #7f7f7f;
$headings-font-family: 'Arial', sans-serif;

@import '~@progress/kendo-theme-bootstrap/dist/all.scss';
@import '~bootstrap/scss/bootstrap.scss';

// These variables will be available throughout the application after SASS compilation
:root {
  --geosap-primary-color: #{$primary};
  --geosap-secondary-color: #{$secondary};
  --geosap-input-border-color: #aac4aa;
  --geosap-input-focus-shadow: #{$kendo-input-focus-shadow};
  --geosap-disabled: #{$disabled-text};
  --geosap-navbar-color: #4a4a4a;
  --geosap-navbar-width: 70px;
  --geosap-navbar-legacy-width: 110px;
  --geosap-delivery-navbar-mode-width: 110px;
  --geosap-navbar-icon-color: #{$light};
  --geosap-navbar-selected-item-color: #3c3c3c;
  --geosap-builder-menu-width: 360px;
  --geosap-viewer-layers-width: 240px;
  --geosap-viewer-toolbar-width: 80px;
  --geosap-viewer-toolbar-height: 60px;
  --geosap-viewer-toolbar-icon-width: 22px;
  --geosap-viewer-status-height: 24px;
  --geosap-datadelivery-navigation-width: 480px;
  --geosap-datadelivery-annotations-width: 360px;
  --geosap-profile-icon-color: #e0e0e0;
  --geosap-card-bg-color: #f2f2f2;
  --geosap-card-secondary-bg-color: #a0c49e;
  --geosap-light: #ffffff;
  --geosap-steps-background: rgba(169, 169, 169, 0.75);
  --geosap-link-hover-color: #{$link-hover-color};
  --geosap-k-button-md: #{$k-button-md};
  --geosap-body-text: #{$body-text};
  --geosap-sapflow-card-border-color: rgba(127, 127, 127, 0.21);
  --geosap-process-number-background: rgba(242, 242, 242, 0.5);
  --geosap-error: #{$error};
  --geosap-light-error: #{$light-error};
  --geosap-dark-error: #{$dark-error};
  --geosap-error-link-hover: #{color.scale($error, $alpha: -40%)};
  --geosap-grid-header-bg: #{$grid-header-bg};
  --geosap-selected: #558955;
  --geosap-selected-blue: #{$selected-blue};
  --geosap-baby-blue: #{$baby-blue};
  --geosap-kendo-button-bg: #{$kendo-button-bg};
  --geosap-sapflow-details-selected-button-bg: #d2dee0;

  --gs-lightgray-bg: #898989;
  --gs-darkgray-bg: #3c3c3c;

  --kendo-color-success: #{$success};
  --kendo-color-on-app-surface: #7f7f7f;
  --kendo-color-surface: #e9f0e9;
  --kendo-font-family: "Arial";
  --kendo-paragraph-font-family: "system-ui","-apple-system","Segoe UI","Roboto","Helvetica Neue","Noto Sans";
  --kendo-h1-font-family: "Arial";
  --kendo-h2-font-family: "Arial";
  --kendo-h3-font-family: "Arial";
  --kendo-h4-font-family: "Arial";
  --kendo-h5-font-family: "Arial";
  --kendo-h6-font-family: "Arial";

  --kendo-h2-font-size: 2rem;

  --kendo-fs-xs: 0.75rem;

  --kendo-font-size: 1rem;
  --kendo-line-height: 1.5;

  --kendo-color-surface: #5C9198;
  //--kendo-color-on-app-surface: #FFFFFF;

  --view-green: #6EB06B;
  --view-green-highlight: #9AC897;
  --view-green-darker: #588D56;
  --info-blue: #28B4D8;
  --warning-yellow: #DDC74D;
  --warning-yellow-highlight: #E7D882;
  --warning-yellow-darker: #B19F3E;
  --hazard-red: #A53F42;
  --light-red: #e09294;

  --status-processing: #F1B951;
  --status-processing-highlight: #F5CB7D;
  --status-new: #00BEFC;
  --error-red: #FF0012;

  --text-color: #7D7D7D;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.font-exo {
  font-family: "Exo 2";
}

/*KENDO CUSTOMIZATION*/
.k-window {
  border-radius: 20px;
  max-width: 600px;
}

.k-theme-color-view-green {
  border-color: var(--view-green);
  background-color: var(--view-green);
  color: white;
  &:hover {
    border-color: var(--view-green-darker);
    background-color: var(--view-green-darker);
  }
}

.k-theme-color-warning-yellow {
  border-color: var(--warning-yellow);
  background-color: var(--warning-yellow);
  color: white;
  &:hover {
    border-color: var(--warning-yellow-darker);
    background-color: var(--warning-yellow-darker);
  }
}

.k-theme-color-outline-warning-yellow {
  border-color: var(--warning-yellow);
  background-color: inherit;
  color: #636363;
  .k-icon {
    color: var(--warning-yellow);
  }
  &:hover {
    border-color: var(--warning-yellow-highlight);
    background-color: var(--warning-yellow-highlight);
    color: white;
    .k-icon {
      color: white;
    }
  }
}

.k-button-outline-tertiary {
  color: #636363;
  border-color: var(--kendo-color-tertiary);
  .k-icon {
    color: var(--kendo-color-tertiary);
  }
  &:hover {
    background: var(--kendo-color-tertiary-emphasis);
    border-color: var(--kendo-color-tertiary-emphasis);
    .k-icon {
      color: var(--kendo-color-on-tertiary);
    }
  }
}

.k-button .k-icon {
  transition: inherit;

}

.k-button-md {
  padding-block: 0.1rem;
  padding-inline: 0.65rem;
  border-width: 2px;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.k-button-sm {
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  border-width: 2px;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.k-expander-title {
  width: 100%;
}

.k-input-inner {
  padding: 0 0.75rem !important;
}

.k-input-button {
  padding: 0 0.25rem !important;
}

.k-menu:not(.k-context-menu) {
  background-color: white;
}

.k-slider-selection {
  background-color: transparent !important;
}

.k-draghandle {
  border-radius: 0 !important;
  border-color: rgb(112, 112, 112) !important;
  width: 6px !important;
  height: 20px !important;
  background-color: rgb(157, 157, 157) !important;
}

.k-slider-track {
  border-radius: 0 !important;
}

.k-slider-items {
  //margin-top: -1rem !important;
}

.k-grid tbody tr:not(.k-detail-row):hover,
.k-grid tbody tr:not(.k-detail-row).k-state-hover {
  background-color: lightgray !important;
}

.k-grid-norecords-template {
  border:none;
}

.k-grid-header {
  padding-inline-end: 0px;
}

.k-grid .k-table-th {
  border:none;
  color: white;
}

.k-grid-content {
  overflow-y: auto;
}

//.k-alt {
//  background: #ededed !important;
//}

.k-grid .k-table {
  border-collapse: collapse;
}

.k-grid .k-table .k-table-thead {
  font-size: 1.2rem;
}

.k-grid .k-table-row {
  border-color: #F0F0F0;
}

.k-grid .k-table-alt-row {
  background-color: inherit;
}

.k-grid-content .k-table-row {
  border-bottom: 3px solid #F0F0F0;
  border-top: none;
}

.k-table-highlight-row {
  background: lightgray !important;
}

.k-column-title {
  width: 100%;
}

.k-pager-first,
.k-pager-last {
  display: none;
}

.k-treeview {
  /*max-height: 400px;*/
}

.k-treeview-leaf,
.k-treeview-leaf-text {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.k-icon-32 {
  font-size: 32px; /* Sets icon size to 32px */
}

.k-icon-48 {
  font-size: 48px; /* Sets icon size to 48px */
}

.k-icon-64 {
  font-size: 64px; /* Sets icon size to 64px */
}

.upload-disabled .k-upload-selected {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-upload .k-progress-status {
  display: none;
}

.k-switch-on .k-switch-thumb {
  background-color: white;
}

.k-window-actions {
  margin: 0;
}

.k-loader {
  display: flex;
  align-items: center;
}

.k-tooltip-content {
  overflow-wrap: anywhere;
}

.k-notification {
  display: flex;
}

.k-notification-status {
  margin-right: 1rem;
}

.k-notification-action {
  display: flex;
  margin-left: 1rem;
}

.k-dialog-actions {
  border: none;
}

.k-slider .k-slider-track {
  background-color: #e9ecef;
}

.k-input {
  font-family: inherit;
}

/* React-Flow Customization */
.react-flow {
  padding-left: var(--geosap-builder-menu-width);
  width: 100%;
  height: 100%;
}

.disabled {
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
}

#potree_description h3 {
  line-height: normal !important;
}
/*GEOSAP CSS*/
/*checked checkbox*/
.layers-checkbox .k-checkbox:checked {
  background-color: $secondary;
  color: white;
  border-color: $secondary;
}

.mobileMenu {
  padding: 0 !important;
  list-style-type: none; /* Remove default bullet points */
  margin: 0; /* Remove default margin */
  
  li {
    border-bottom: 2px solid #7f7f7f; /* Add bottom border to each li */
    padding: 10px; /* Optional: Add padding for better spacing */
  }
  
  li:last-child {
    border-bottom: none; /* Remove bottom border from the last li */
  }
}

.placeholder-hidden-on-focus {
  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }
}

.link-disabled {
  pointer-events: none;
  color: rgb(112, 112, 112) !important;
  text-decoration: none !important;
}

.grayed {
  color: rgb(172, 172, 172) !important;
  text-decoration: none !important;
}

.animated-transform {
  -webkit-transition: -webkit-transform 0.15s ease-in-out;
  -ms-transition: -ms-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
}

.rotated-0 {
  transform: rotate(0deg);
}
.rotated--90 {
  transform: rotate(-90deg);
}
.rotated-90 {
  transform: rotate(90deg);
}
.rotated-180 {
  transform: rotate(180deg);
}

.animated-general {
  transition: all 0.15s ease-in-out;
}

.animated-opacity {
  transition: opacity 0.2s ease-in-out;
}

.animated-background {
  transition: background-color 0.15s ease-in-out;
}

.animated-color {
  transition: color 0.15s ease-in-out;
}

.animated-width {
  transition: width 0.15s ease-in-out;
}

.animated-height {
  transition: height 0.15s ease-in-out;
}

.animated-left {
  transition: left 0.15s ease-in-out;
}

.searchbox-input:focus {
  outline: none;
}

.searchbox-input::placeholder {
  color: rgb(190, 190, 190);
}

.focus-no-outline:focus,
.focus-no-outline .k-button:focus {
  outline: 0 none;
  box-shadow: none;
}

.cesium-widget-credits {
  display: none !important;
}

.display-none {
  display: none !important;
}

.leaflet-container {
  height: 100%;
}

.fill-width {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.h-fit {
  height: fit-content;
}

.mw-200 {
  max-width: 200px;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(127, 127, 127, 0.21);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.pixel-infos {
  width: 240px;
  padding: 8px;
  bottom: 80px;
  left: 320px;
  display: flex;
  flex-direction: column;
}
